.other2 {
  flex: 4 1;
  padding: 4em 0em;
  width: 100vw;
  /* background: #0d0c14; */
  padding-left: 0em;
  padding-top: 1em;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
}

.no-bg {
  background: none !important;
  padding: 2em;
}
.no-bg .container {
  max-width: 1450px;
  /* overflow: hidden; */
}

/* @media screen and (max-width: 500px) {
  .no_pad {
    padding: 2em 0em !important;
  }
} */
