@import "~react-image-gallery/styles/css/image-gallery.css";
@import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";

.updatedItemDetailPageSection1 {
  padding: 4em 0em;
  padding-top: 2em;
  padding-bottom: 1em;
  position: sticky;
  top: 0%;
  z-index: 5;
  background: #fff;
}

.dark .updatedItemDetailPageSection1 {
  background: #151422;
  color: #fff;
}

.updatedItemDetailPageSection2 {
  padding: 4em 0em;
  padding-top: 0em;
}

.no_data_grade_div {
  text-align: center;
}

.updatedItemDetailPageSection1_area_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 30px;
  grid-gap: 30px;
  gap: 30px;
  box-shadow: 0px 3px 0px 0px #e0e0e0;
  padding: 1em 2em;
}

.dark .updatedItemDetailPageSection1_area_head {
  box-shadow: 0px 3px 0px 0px #373737;
}

.updatedItemDetailPageSection1_area_head_1 {
  display: flex;
  flex-direction: column;
}

.buy_button {
  background: #7a5fc0;
  color: #fff;
  border: none;
  padding: 10px;
  width: 200px;
  border-radius: 150px;
}

.updatedItemDetailPageSection1_area_head_span1 {
  color: #7a5fc0;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
}

.updatedItemDetailarea_head_heading {
  font-size: 24px;
  color: #000;
  font-size: 24px;
  font-weight: 500;
}

.dark .updatedItemDetailarea_head_heading {
  color: #ffffff;
}

.updatedItemDetailPageSection1_area_head_span2 {
  font-size: 12px;
}

.updated_itemdisplay {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 6em;
}

.updated_itemdisplay_area2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-left: 3%;
}

.product_detail_section {
  padding: 0em 0em !important;
  padding-bottom: 2em !important;
  position: relative;
  /* overflow: hidden; */
}

.updated_itemdisplay_area2_cont1 {
  display: flex;
  flex-direction: column;
  grid-gap: 0px;
  gap: 0px;
  /* border-bottom: solid 1px #d0d0d0; */
  padding: 1em 0em;
  padding-bottom: 0;
}

.power_details_details_div_cont1_div1 {
  color: #8a87a7;
}

/* .dark .updated_itemdisplay_area2_cont1 {
  border-bottom: solid 1px #414141;
} */
.updated_itemdisplay_area2_cont1_condition_tag_div {
  width: 100%;
}

.updated_itemdisplay_area2_cont1_condition_tag {
  background: #7a5fc0;
  color: #fff;
  width: 100px;
  padding: 5px;
  border-radius: 50px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.updated_itemdisplay_area2_cont1_product_name {
  font-size: 48px;
  font-weight: 600;
}

.dark .updated_itemdisplay_area2_cont1_product_name {
  color: #fff;
}

.updated_itemdisplay_area2_cont1_rating_div1_productCode {
  font-size: 12px;
  color: #a4a4a4;
}

.updated_itemdisplay_area2_cont2 {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  border: solid 1px #efefef;
  padding: 10px;
  border-radius: 7px;
  background: #f6f6f6;
}

.updated_itemdisplay_area2_cont2_head {
  font-size: 24px;
  font-weight: 600;
}

.updated_itemdisplay_area2_cont2_heading {
  font-size: 24px;
  font-weight: 500;
}

.updated_itemdisplay_area2_cont2_choose_grade_select {
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
}

.updated_itemdisplay_area2_cont2_choose_grade_select1 {
  padding: 2em;
  border: solid 1px #d0d0d0;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
}

.updated_itemdisplay_area2_cont2_choose_grade_select1_head {
  font-size: 18px;
  font-weight: 500;
}

.updated_itemdisplay_area2_cont2_choose_grade_select1_price {
  font-size: 13px;
  font-weight: 300;
}

.updated_itemdisplay_area2_cont1_condition_tag_div {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.updated_itemdisplay_area2_cont1_product_name_link {
  font-size: 18px;
  color: #000;
  text-decoration: underline !important;
}

.Updated_itemdisplay_payment_proceed_div_1 {
  padding: 1em 2em;
  background: #f6f6f6;
  border-radius: 7px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.dark .Updated_itemdisplay_payment_proceed_div_1 {
  background: #151422;
  color: #fff;
}

.Updated_itemdisplay_payment_proceed_div_1a {
  padding: 2em;
  background: #f6f6f6;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* align-items: center; */
}

.Updated_itemdisplay_payment_proceed_delivery_div_body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.UpdateddetailPgae_carous_card {
  width: 100%;
  height: 100%;
}

.Updated_itemdisplay_payment_proceed_delivery_div_head {
  font-size: 24px;
  font-weight: 600;
}

.Updated_itemdisplay_payment_proceed_delivery_div_body1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.Updated_itemdisplay_payment_proceed_delivery_div_body2 {
  border-top: solid 1px #e1e1e1;
  padding: 15px 0px;
  display: flex;
  gap: 10px;
  padding-bottom: 0;
}

.Updated_itemdisplay_payment_proceed_delivery_div_body1_head {
  font-weight: 500;
}

.region_select {
  -webkit-appearance: none;
  -moz-appearance: none;
  /* background: transparent; */
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 2rem;
  padding: 1rem;
  padding-right: 2rem;
  border-radius: 8px;
  border: solid 1px #c7c7c7;
  cursor: pointer;
  width: 100%;
}

.Updated_itemdisplay_payment_proceed_div_1_cont1_icon {
  font-size: 52px !important;
  color: #393939;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 66px;
  opacity: 0.4;
}

.Updated_itemdisplay_payment_proceed_div_1_cont1_icon svg {
  margin-bottom: 20px;
}

.dark .Updated_itemdisplay_payment_proceed_div_1_cont1_icon svg {
  filter: invert(1);
}

.Updated_itemdisplay_payment_proceed_delivery_div_body2a {
  display: flex;
  gap: 10px;
  padding: 10px;
}

.Updated_itemdisplay_payment_proceed_delivery_div_body2a_icon {
  padding: 5px;
  border: solid 1px #c2c2c2;
  border-radius: 4px;
  font-size: 38px !important;
}

.Updated_itemdisplay_payment_proceed_delivery_div_body2a_txt_head {
  font-weight: 600;
}

.Updated_itemdisplay_payment_proceed_delivery_div_body2a_txt_body {
  font-size: 14px;
  font-weight: 400;
  color: #6b6b6b;
}

.Updated_itemdisplay_payment_proceed_div_1_cont1_txt {
  font-weight: 600;
}

.Updated_itemdisplay_payment_proceed_div_1_cont2 {
  font-size: 12px;
  font-weight: 300;
}

.image-gallery-content {
  display: flex !important;
  grid-gap: 20px !important;
  gap: 10% !important;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 60px !important;
  width: 30px !important;
}

.updated_itemdisplay_area1 {
  width: 84%;
  margin: auto;
  margin-top: 0;
  position: sticky;
  top: 0%;
  z-index: 4;
}

.image-gallery-thumbnails-wrapper.left,
.image-gallery-thumbnails-wrapper.right {
  width: 20% !important;
}

.image-gallery-slide-wrapper.left,
.image-gallery-slide-wrapper.right {
  width: 100% !important;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 60px !important;
  width: 30px !important;
}

.image-gallery-content.left .image-gallery-slide .image-gallery-image,
.image-gallery-content.right .image-gallery-slide .image-gallery-image {
  width: 100% !important;
  object-fit: contain !important;
}

.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail {
  width: 100% !important;
}

.image-gallery-bullets {
  bottom: -30px !important;
  left: 0;
  /* margin: 0 auto; */
  position: absolute;
  right: 0;
  width: 100% !important;
  z-index: 4;
  border: solid 1px #bababa !important;
  margin: auto !important;
  padding: 3px 0px !important;
  border-radius: 20px !important;
  max-width: 68px !important;
}

.image-gallery-bullets .image-gallery-bullet {
  background-color: #aeaeae !important;
  border: 1px solid #aeaeae !important;
  box-shadow: none !important;
}

.image-gallery-bullets .image-gallery-bullet.active {
  border: 1px solid #000000 !important;
  background: #000000 !important;
}

.image-gallery-thumbnail:hover {
  outline: none;
  border: 1px solid #000000 !important;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  outline: none;
  border: 1px solid #000000 !important;
}

.updatedItemDetailPageSection1_area {
  display: flex;
  align-items: center;
  width: 100%;
}

.updatedItemDetailPageSection1_area_amount {
  font-size: 32px;
  font-weight: 600;
  margin-right: 20px;
}

.Updated_itemdisplay_payment_proceed_div_2 {
  background: #f6f6f6;
  padding: 2em;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dark .Updated_itemdisplay_payment_proceed_div_2 {
  background: #151422;
  color: #fff;
}

.Updated_itemdisplay_payment_proceed_div_2_area1 {
  font-weight: 600;
}

.Updated_itemdisplay_payment_proceed_div_2_area2 {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}

.Updated_itemdisplay_payment_proceed_div_2_area2_area2 {
  font-weight: 600;
}

.Updated_itemdisplay_payment_proceed_div_2_area3_txt {
  font-size: 14px;
}

.Updated_itemdisplay_payment_proceed_div_2_area4_txt {
  font-size: 14px;
}

.Updated_itemdisplay_payment_proceed_div_2_area5 {
  display: flex;
  justify-content: space-between;
  font-size: 28px;
  font-weight: 600;
  align-items: center;
}

.Updated_itemdisplay_payment_proceed_div_2_area5_area1 {
  font-weight: 400;
  font-size: 18px;
}

.Updated_itemdisplay_payment_proceed_div_2_area6 {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.continue_to_checkout_btn_link {
  width: 80%;
  margin: auto;
}

.continue_to_checkout_btn {
  width: 100%;
  padding: 15px 0px;
  border-radius: 50px;
  border: none;
  background: #7a5fc0;
  color: #fff;
  margin-top: 20px;
  font-size: 14px;
}

.continue_to_checkout_btna {
  width: 100%;
  padding: 15px 0px;
  border-radius: 50px;
  border: none;
  background: #7a5fc0;
  color: #fff;
  font-size: 14px;
}

.continue_to_checkout_btn:disabled {
  cursor: not-allowed;
  background: #dadada;
  color: #939393;
}

.continue_to_checkout_btna:disabled {
  cursor: not-allowed;
  background: #dadada;
  color: #939393;
}

.Updated_itemdisplay_payment_proceed_div_2_area3 {
  display: flex;
  align-items: center;
}

.Updated_itemdisplay_payment_proceed_div_2_area4 {
  display: flex;
  align-items: center;
}

.Updated_itemdisplay_payment_proceed_div_2_area3_icon {
  color: #adadad;
  width: 40px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Updated_itemdisplay_payment_proceed_div_2_area3_icon svg {
  width: 100% !important;
  height: 40px !important;
}

.Updated_itemdisplay_payment_proceed_div_2_area4_icon {
  color: #adadad;
  width: 40px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Updated_itemdisplay_payment_proceed_div_2_area4_icon svg {
  width: 100% !important;
  height: 25px !important;
}

.Updated_itemdisplay_payment_proceed_div_2_area5_area2 {
  font-weight: 600;
}

.Updated_itemdisplay_payment_proceed_div_2_area6_area2 {
  font-weight: 600;
}

.updated_itemdisplay_area2_cont2_choose_grade_select1_active {
  padding: 2em;
  border: solid 1px #7a5fc0;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  background: #7a5fc0;
  color: white;
}

.updated_itemdisplay_area2_cont2_choose_grade_select1:hover {
  border: solid 1px #7a5fc0;
  color: #7a5fc0;
}

.updated_itemdisplay_area2_cont1_rating_div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.updated_itemdisplay_area1_grades_display_area_2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 1em;
  background: #f6f6f6;
  border-radius: 7px;
}

.updated_itemdisplay_area1_grades_display_area_2_cont1 {
  display: flex;
  background: white;
  padding: 1em;
  align-items: center;
  border: solid 1px #7a5fc0;
  border-radius: 10px;
  box-shadow: 0px 4px 10px #e2e2e2;
}

.updated_itemdisplay_area1_grades_display_area_2_cont1_img_cont {
  width: 15%;
}

.updated_itemdisplay_area1_grades_display_area_2_cont1_img {
  width: 100%;
}

.updated_itemdisplay_area1_grades_display_area_2_cont1_deatil_area {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.updated_itemdisplay_area1_grades_display_area_2_cont1_deatil_area1 {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.add_btn {
  font-size: 12px;
  padding: 4px 0px;
  width: 50px;
  border: none;
  border-radius: 25px;
  background: #7a5fc0;
  color: #fff;
}

.add_btn:disabled {
  background: #b4b4b4;
  color: #fff;
  cursor: not-allowed;
}

button.more_details_btn {
  width: 136px;
  font-size: 12px;
  padding: 10px 0px;
  border-radius: 100px;
  border: none;
  background: #919191;
  color: #fff;
}

.updated_itemdisplay_area1_grades_display_area_2_cont1_deatil_area1_cont1_txt {
  font-weight: 600;
  font-size: 18px;
}

.updated_itemdisplay_area1_grades_display_area_2_cont1_deatil_area1_cont1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.updated_itemdisplay_area1_grades_display_area_2_cont1_deatil_area1_cont3 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 6px;
}

a.More_details_link {
  font-size: 12px;
  color: #000;
  text-decoration: underline !important;
}

.updated_itemdisplay_area1_grades_display_area_1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 1em;
  background: #f6f6f6;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  margin-bottom: 10px;
}

.updatedItemDetailPageSection3 {
  padding: 4em 0em;
  padding-top: 2em;
  position: relative;
  border-top: solid 1px #e7e7e7;
}

.dark .updatedItemDetailPageSection3 {
  border-top: solid 1px #2e2e2e;
}

.product_specifications_area {
  padding: 0em;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 0;
  border-radius: 10px;
  max-height: 100em;
  height: 100%;
  overflow: scroll;
  border: solid 1px #e5e5e5;
}

.dark .product_specifications_area {
  background: #151422;
  border: solid 1px #2e2c47;
}

.product_specifications_area_head {
  font-size: 17px;
  font-weight: 500;
  width: 100%;
  /* padding: 15px; */
  background: #ffffff;
  /* cursor: pointer; */
  display: flex;
  position: sticky;
  top: 0;
}

.dark .product_specifications_area_head {
  background: #151422;
}

.product_specifications_area_body {
  width: 100% !important;
  background: #f6f6f6;
  padding: 20px;
}

.dark .product_specifications_area_body {
  background: #151422;
  color: #fff;
}

.product_specifications_area_body>img {
  width: 100% !important;
}

.product_specifications_area_body>p {
  width: 100% !important;
  background: inherit !important;
}

.product_specifications_area_body>p span {
  background: inherit !important;
}

.product_specifications_area_body>ul li span {
  background: inherit !important;
}

.product_specifications_area_body>h1 span {
  background: inherit !important;
}

.product_specifications_area_body>h2 span {
  background: inherit !important;
}



.product_specifications_area_body>h3 span {
  background: inherit !important;
}

.product_specifications_area_body>h4 span {
  background: inherit !important;
}

.product_specifications_area_body>h5 span {
  background: inherit !important;
}

.product_specifications_area_body>h6 span {
  background: inherit !important;
}

.dark .product_specifications_area_body>h1 span {
  background: inherit !important;
  color: #fff !important;
}

.dark .product_specifications_area_body>h2 span {
  background: inherit !important;
  color: #fff !important;
}

.dark .product_specifications_area_body>h3 span {
  background: inherit !important;
  color: #fff !important;
}

.dark .product_specifications_area_body>h4 span {
  background: inherit !important;
  color: #fff !important;
}

.dark .product_specifications_area_body>h5 span {
  background: inherit !important;
  color: #fff !important;
}

.dark .product_specifications_area_body>h6 span {
  background: inherit !important;
  color: #fff !important;
}

.dark .product_specifications_area_body>ul li span {
  background: inherit !important;
  color: rgb(255 255 255) !important;
}

.dark .product_specifications_area_body>p span {
  background: inherit !important;
  color: rgb(255 255 255) !important;
}

.product_specifications_area_head_tab_active {
  padding: 18px;
  background: #f6f6f6;
  cursor: pointer;
  /* box-shadow: 0px 0px 8px #d2d2d2; */
  color: #3b3b3b;
  font-weight: 500;
}

.dark .product_specifications_area_head_tab_active {
  background: #151422;
  color: #ffffff;
}

.product_specifications_area_head_tab {
  padding: 20px;
  cursor: pointer;
  color: #bfbfbf;
  font-weight: 400;
  font-size: 16px;
}

.save_button_div {
  display: flex;
  justify-content: flex-end;
}

.save_button_btn {
  width: 100px;
  padding: 6px;
  font-size: 14px;
  border-radius: 50px;
  border: none;
  background: #7a5fc0;
  color: #fff;
}

.save_button_btn:disabled {
  background: #d9d9d9;
  color: #7f7f7f;
  cursor: not-allowed;
}

.accordion_body_item_detail {
  /* border: solid 1px #e8e8e8; */
  padding: 20px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  border-top: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #fff;
}

.accordion-item.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
  border: none !important;
}

.accordion-title.open {
  border-radius: 0px !important;
  border-bottom: 0px !important;
  border-top-right-radius: 7px !important;
  border-top-left-radius: 7px !important;
  padding: 30px 15px;
  font-weight: 500;
  background: #f8f8f8;
}

.accordion-title {
  border-radius: 7px !important;
  padding: 30px 15px;
  font-weight: 500;
  background: #fff;
}

.tagconditionDiv {
  font-size: 10px;
  background: #7a5fc0;
  color: #fff;
  padding: 3px 7px;
  border-radius: 40px;
  margin-left: 15px;
}

.tagconditionDiv2 {
  font-size: 10px;
  background: #ff4c4c;
  color: #fff;
  padding: 2px 8px;
  border-radius: 50px;
  margin-left: 15px;
}

.tagconditionDiv_cont {
  display: flex;
  align-items: center;
  font-weight: 600;
}

.faq_area {
  display: flex;
  flex-direction: column;
}

.accordin_detail_title {
  margin-bottom: 1em;
  font-size: 24px;
  font-weight: 600;
}

.faq_area1 {
  margin-bottom: 1em;
}

.UpdateddetailPgae_carous .react-multi-carousel-item {
  transform-style: preserve-3d;
  backface-visibility: hidden;
  width: 340px !important;
}

.UpdateddetailPgae_carous .react-multi-carousel-track {
  gap: 20px;
}

.updatedItemDetailPageSection3_carous_div {
  background: #f8f8f8;
  border-top: solid 1px #e4e4e4;
}

.similar_prod_area_heading {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 1em;
}

.similar_prod_area {
  display: flex;
  flex-direction: column;
}

.Updated_itemdisplay_payment_proceed_div_payment_waya {
  display: flex;
  align-items: center;
  border: solid 1px #c2c2c2;
  border-radius: 6px;
  padding: 5px;
  width: 25em;
}

@media (max-width: 991px) {
  .updated_itemdisplay {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
  }

  .updated_itemdisplay_area1 {
    width: 100%;
    margin: auto;
    margin-top: 0;
    position: relative;
    margin-bottom: 3em;
    /* top: 25%; */
    /* z-index: 4; */
  }

  .updated_itemdisplay_area2 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    margin-left: 0;
  }

  .add_quantity_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border-top: solid 1px #d7d7d7; */
    padding-top: 1em;
    background: #f6f6f6;
    border-radius: 6px;
    padding: 1em 1em;
    flex-direction: column;
    align-items: flex-start;
  }

  .add_quantity_div2 {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .updatedItemDetailPageSection1 {
    padding: 1em 0em;
    padding-top: 1em;
    padding-bottom: 1em;
    position: fixed;
    /* top: 90%; */
    bottom: -90%;
    z-index: 10000000000;
    background: #fff;
    height: 10%;
    right: 0;
    width: 100%;
    box-shadow: 0px 0px 10px #dddddd;
    margin: auto;
  }

  .updatedItemDetailPageSection1_area_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 30px;
    grid-gap: 30px;
    grid-gap: 30px;
    grid-gap: 30px;
    gap: 30px;
    box-shadow: none;
    padding: 0em 0em;
    width: 100%;
  }

  .updatedItemDetailPageSection1_area_head_1 {
    display: none;
    flex-direction: column;
  }

  .updatedItemDetailPageSection1_area_head_1b {
    width: 100%;
  }

  .updatedItemDetailPageSection1_area {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .updatedItemDetailPageSection1_area_amount {
    font-size: 24px;
    font-weight: 600;
    margin-right: 0;
  }

  .updatedItemDetailPageSection2 {
    padding: 4em 0em;
    padding-top: 4em;
  }

  .updated_itemdisplay_area2_cont1_product_name {
    font-size: 28px;
    font-weight: 600;
  }

  .updated_itemdisplay_area2_cont2_head {
    font-size: 20px;
    font-weight: 600;
  }

  .updated_itemdisplay_area2_cont2_choose_grade_select1 {
    padding: 1em;
    border: solid 1px #d0d0d0;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;
  }

  .updated_itemdisplay_area2_cont2_choose_grade_select1_active {
    padding: 1em;
    border: solid 1px #7a5fc0;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;
    background: #7a5fc0;
    color: white;
  }

  .updated_itemdisplay_area1_grades_display_area_2_cont1_deatil_area1_cont1_amount {
    font-size: 12px;
  }

  .Updated_itemdisplay_payment_proceed_div_1 {
    padding: 1em;
    background: #f6f6f6;
    border-radius: 7px;
    display: flex;
    grid-gap: 5px;
    gap: 5px;
    align-items: center;
  }

  .Updated_itemdisplay_payment_proceed_div_2 {
    background: #f6f6f6;
    padding: 1em;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
  }

  .Updated_itemdisplay_payment_proceed_div_payment_way_cont1_active {
    width: 100%;
    background: #7a5fc0;
    color: #fff;
    padding: 1em 0em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 7px;
    font-size: 14px;
  }

  .Updated_itemdisplay_payment_proceed_div_payment_way_cont1 {
    width: 100%;
    color: #bababa;
    padding: 1em 0em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 7px;
    font-size: 14px;
  }

  .Updated_itemdisplay_payment_proceed_div_payment_way {
    display: flex;
    align-items: center;
    border: solid 1px #c2c2c2;
    border-radius: 6px;
    padding: 5px;
  }

  .updated_itemdisplay_area1_grades_display_area_2_cont1_img_cont {
    width: 30%;
  }

  .updated_itemdisplay_area1_grades_display_area_2_cont1 {
    display: flex;
    background: white;
    padding: 0.5em;
    align-items: center;
    border: solid 1px #d9d9d9;
    border-radius: 10px;
    /* box-shadow: 0px 4px 10px #e2e2e2; */
  }

  .updated_itemdisplay_area1_grades_display_area_2_cont1_deatil_area1_cont1_txt {
    font-weight: 600;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90px;
  }
}

@media (max-width: 765px) {

  .image-gallery-thumbnails-wrapper.left,
  .image-gallery-thumbnails-wrapper.right {
    display: none;
  }
}