.checkoutPage_header {
  font-size: 32px;
  font-weight: 500;
  color: #4b4b4b;
}

.payViaFortIconImg {
  width: 15px;
  margin-left: 5px;
}

.dark .checkoutPage_header {
  color: #fff;
}

.checkoutPage_body {
  margin-top: 1em;
  display: flex;
}

.checkoutPage_body1 {
  width: 100%;
  margin-right: 1em;
  display: flex;
  flex-direction: column;
}

.checkoutPage_body2 {
  width: 44%;
}

.checkoutPage_body1_cont1 {
  display: flex;
  flex-direction: column;
}

.checkoutPage_body1_cont1_head {
  padding: 20px;
  background: #c2c2c2;
  border-radius: 8px;
  color: #000000;
  font-size: 18px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dark .checkoutPage_body1_cont1_head {
  background: #212036;
  color: #a09eb8;
}

.checkoutPage_body1_cont1_head_span {
  width: 100%;
}

.checkoutPage_body1_cont1_body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  border: solid 1px #dbdbed;
  padding: 30px;
  border-radius: 12px;
  background: #ffffff;
}

.dark .checkoutPage_body1_cont1_body {
  border: solid 1px #2b2a41;
  background: #181621;
}

.checkoutPage_body1_cont1_body_body1 {
  display: flex;
  flex-direction: column;
  color: #53526d;
  width: 100%;
}

.checkoutPage_body1_cont1_body_cont1_body {
  color: rgb(0, 0, 0);
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.dark .checkoutPage_body1_cont1_body_cont1_body {
  color: #fff;
}

.checkoutPage_body1_cont1_body_btn_div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.checkoutPage_body1_cont1_body_btn {
  padding: 10px;
  width: 100%;
  max-width: 86px;
  border-radius: 9px;
  background: #7a5fc0;
  color: #fff;
  font-weight: 500;
  border: none;
  font-size: 13px;
}

.checkoutPage_body1_cont1_body_btn:disabled {
  background: #9d95b5;
  color: #bdb7ce;
  cursor: not-allowed;
}

.dark .checkoutPage_body1_cont1_body_btn:disabled {
  background: #362d4e;
  color: #615978;
  cursor: not-allowed;
}

.checkoutPage_body1_cont1_body_cont1_body_span {
  margin-right: 11px;
}

.payment_method_area1_cont1_label {
  display: inline-block;
  margin-right: 20px;
  position: relative;
  cursor: pointer;
}

.payment_method_area1_cont1_label:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

input[type="radio"]:checked+.payment_method_area1_cont1_label:before {
  background-color: #7a5fc0;
  border-color: #997fda;
  padding: 2px;
}

.payment_method_div {
  padding: 2em 1.2em;
  background: #ffffff;
  border-radius: 10px;
  border: solid 1px #dbdbed;
  display: flex;
}

.dark .payment_method_div {
  background: #181621;
  border: solid 1px #2b2a41;
}

.payment_method_div_cont1 {
  display: flex;
  align-items: center;
  padding: 1em;
  border: solid 1px #c9c9d4;
  cursor: pointer;
  margin: 0px 5px;
  border-radius: 10px;
  width: 100%;
}

.dark .payment_method_div_cont1 {
  border: solid 1px #2e2c38;
}

.payment_method_div_cont1_active {
  display: flex;
  align-items: center;
  padding: 1em;
  border: solid 2px #7a5fc0;
  cursor: pointer;
  margin: 0px 5px;
  border-radius: 10px;
  width: 100%;
}

.payment_method_div_cont1_div1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment_method_div_cont1_div2 {
  margin-left: 10px;
  font-size: 18px;
  color: rgb(0, 0, 0);
}

.dark .payment_method_div_cont1_div2 {
  color: #fff;
}

.proceedToPayDiv {
  padding: 2em;
  background: #ffffff;
  border-radius: 10px;
  margin-top: 10px;
  border: solid 1px #e6e5ef;
  display: flex;
  flex-direction: column;
}

.dark .proceedToPayDiv {
  background: #181621;
  border: solid 1px #2b2a41;
}

.proceedToPayDiv1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0em;
  border-bottom: solid 1px #dddaeb;
  color: #827e95;
  font-size: 14px;
}

.dark .proceedToPayDiv1 {
  border-bottom: solid 1px #2e2c38;
  color: #827e95;
}

.proceedToPayDiv1_value {
  color: #000;
  font-size: 16px;
}

.dark .proceedToPayDiv1_value {
  color: #fff;
}

.proceedToPayDiv_btn_div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
}

.proceedToPayDiv_btn {
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  border: none;
  background: #7a5fc0;
  color: #fff;
}

.proceedToPayDiv_btn:disabled {
  background: #c2c2c2;
  color: #ffffff;
  cursor: not-allowed;
}

.dark .proceedToPayDiv_btn:disabled {
  background: #362d4e;
  color: #615978;
  cursor: not-allowed;
}

.checkoutPage_body2 {
  width: 44%;
}

.checkoutPage_body2_area {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 1em;
  border-radius: 11px;
  border: solid 1px #dbdbe0;
}

.dark .checkoutPage_body2_area {
  background: #181621;
  border: solid 1px #2b2a41;
}

.checkoutPage_body2_area_title {
  color: #7e7c9c;
  font-size: 24px;
  border-bottom: solid 1px #e9e9ef;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dark .checkoutPage_body2_area_title {
  color: #7e7c9c;
  border-bottom: solid 1px #2b2a41;
}

.checkoutPage_body2_area_title_span {
  font-size: 18px;
  color: rgb(0, 0, 0);
}

.dark .checkoutPage_body2_area_title_span {
  color: #fff;
}

.checkoutPage_body2_area_body {
  width: 100%;
  margin-top: 1em;
  padding: 0em 1em;
  height: 100%;
  max-height: 300px;
  overflow-x: scroll;
}

.checkoutPage_body2_area_body_cont1 {
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: solid 1px #e7e7f1;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.dark .checkoutPage_body2_area_body_cont1 {
  border-bottom: solid 1px #2b2a41;
}

.checkoutPage_body2_area_body_cont1_image_div {
  width: 120px;
  background: #d6d6d6;
  padding: 10px 5px;
  border-radius: 6px;
}

.dark .checkoutPage_body2_area_body_cont1_image_div {
  background: #212036;
}

.checkoutPage_body2_area_body_cont1_image {
  width: 100%;
}

.checkoutPage_body2_area_body_cont1_text {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  gap: 1px;
}

.checkoutPage_body2_area_body_cont1_text_title {
  font-weight: 600;
  color: rgb(0, 0, 0);
  font-size: 20px;
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dark .checkoutPage_body2_area_body_cont1_text_title {
  color: #fff;
}

.checkoutPage_body2_area_body_cont1_text_para1 {
  display: flex;
  color: #53526d;
  font-size: 12px;
  align-items: center;
  /* justify-content: space-between; */
}

.checkoutPage_body2_area_body_cont1_text_para1_title {
  margin-right: 10px;
}

.checkoutPage_body2_area_body_cont1_text_para1_para {
  color: #a09eb8;
  align-items: center;
  display: flex;
}

.checkoutPage_body2_area_body_cont1_text_amount {
  font-size: 15px;
  color: rgb(0, 0, 0);
}

.dark .checkoutPage_body2_area_body_cont1_text_amount {
  color: #fff;
}

.checkoutPage_body2_area_total {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1em;
  background: #e3e3e3;
  margin-top: 1em;
  border-radius: 10px;
  color: #000;
  font-size: 24px;
  align-items: center;
}

.dark .checkoutPage_body2_area_total {
  background: #212036;
  color: #fff;
}

.checkoutPage_body2_area_total_title {
  font-size: 20px;
}

.checkoutPage_body2_area_total_amount {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.remove_prod_btn {
  width: 26px;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: none;
  background: #cc3939;
  color: #fff;
}

.remove_prod_btn_icon {
  font-size: 18px !important;
}

.remove_prod_btn:disabled {
  background: #a85c5c;
  color: #cfb0b0;
  cursor: not-allowed;
}

.updateProfileDivModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0d0c1487;
  backdrop-filter: blur(5px);
  z-index: 100000;
  overflow: scroll;
  padding: 20px;
}

.updateProfileDivModal_container {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  background: #181621;
  padding: 2em;
  border-radius: 12px;
  box-shadow: 0px 0px 15px #000;
  border: solid 1px #2b2a41;
  overflow: scroll;
  /* height: 100vh; */
  max-height: 100%;
}

.updateProfileDivModal_container_title {
  font-size: 24px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 10px;
}

.updateProfileDivModal_container_body {
  display: flex;
  flex-direction: column;
}

.updateProfileDivModal_container_body_cont1 {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.updateProfileDivModal_container_body_cont1_title {
  font-size: 12px;
  color: #827e95;
  margin-bottom: 5px;
}

.updateProfileDivModal_container_body_cont1_input {
  padding: 15px;
  border-radius: 13px;
  border: solid 1px #7a5fc0a6;
  background: no-repeat;
  color: #9e9cb6;
}

.updateProfileDivModal_container_body_cont1_btns {
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.updateProfileDivModal_container_body_cont1_button1 {
  width: 100%;
  margin-right: 5px;
  padding: 10px;
  border: none;
  border-radius: 11px;
  color: #fff;
  background: #7a5fc0;
}

.updateProfileDivModal_container_body_cont1_button2 {
  width: 100%;
  margin-left: 5px;
  padding: 10px;
  border: none;
  border-radius: 10px;
  background: #c72f2f;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .checkoutPage_body {
    margin-top: 1em;
    display: flex;
    flex-direction: column-reverse;
  }

  .checkoutPage_body2 {
    width: 100%;
    margin-bottom: 10px;
  }

  .payment_method_div {
    padding: 1em 1em;
    flex-direction: column;
  }

  .payment_method_div_cont1 {
    margin: 0;
    margin-bottom: 10px;
  }

  .payment_method_div_cont1_active {
    margin: 0;
    margin-bottom: 10px;
  }
}