.Swap_icondropDownDiv {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.swap_price_slippage_div_cont_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* ==================== */
/* ==================== */
/* ==================== */
/* ==================== */
.resfresh_icon_div {
  display: flex;
  align-items: center;
  /* flex-direction: row-reverse; */
}

.resfresh_icon_div_span {
  font-size: 12px;
  color: #5c5a73;
}



.resfresh_icon_div_icon {
  width: 100%;
}

/* ==================== */
/* ==================== */
/* ==================== */
/* ==================== */
/* ==================== */
/* ==================== */
/* ==================== */
/* ==================== */
/* ==================== */
/* ==================== */
/* ==================== */
/* ==================== */
.token_balances_span {
  /* color: #fff; */
  font-size: 11px;

  color: #aaa8c1;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
}

.TokenBalanceIcon {
  font-size: 14px !important;
  margin-right: 0px;
}

.swapDivCont {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
}

.updatedSwapSwapBtn {
  padding: 15px;
  width: 100%;
  border-radius: 15px;
  font-size: 15px;
  background: #7a5fc0;
  color: #ffff;
  border: none;
  font-weight: 500;
}

.updatedSwapSwapBtn:disabled {
  background: #aaa8c1;
  color: #d4d3e3;
  cursor: not-allowed;
}

.dark .updatedSwapSwapBtn:disabled {
  background: #28263e;
  color: #65637a;
}

.amnt_input_layer1_input_div_dollar_value {
  font-size: 14px;
  color: #3e3c59;
}

.amnt_input_layer1_input_div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.amnt_input_layer1 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
}

.amount_loading_div {
  width: 100%;
}

.amnt_input_layer2 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 0px;
}

.amnt_input_layer2_cont1 {
  width: 100%;
  font-size: 12px;
  border-radius: 6px;
  border: none;
  padding: 6px;
  margin-right: 15px;
  background: #aaa8c1;
  color: #fff;
  transition: 0.2s linear;
}

.dark .amnt_input_layer2_cont1 {
  background: #28263e;
  color: #fff;
}

.amnt_input_layer2_cont1_last {
  width: 100%;
  font-size: 12px;
  border-radius: 6px;
  border: none;
  padding: 6px;
  margin-right: 0px;
  background: #aaa8c1;
  color: #fff;
  transition: 0.2s linear;
}

.dark .amnt_input_layer2_cont1_last {
  background: #28263e;
  color: #fff;
}

.amnt_input_layer2_cont1:hover {
  background: #28263e;
  transition: 0.2s linear;
}

.amnt_input_layer2_cont1_last:hover {
  background: #28263e;
  transition: 0.2s linear;
}

.toggle_swap_inputs {
  position: absolute;
  background: #f3f3f3;
  padding: 5px;
  font-size: 47px !important;
  border-radius: 50%;
  right: 0;
  left: 0;
  margin: auto;
  /* border: solid 1px #293d32; */
  color: #9e9cb8 !important;
  cursor: pointer;
  top: 15%;
  bottom: 0;
  margin: auto;
  border: solid 5px #ffffff;
}

.dark .toggle_swap_inputs {
  background: #0f0e1c;
  color: #a8b9aa !important;
  border: solid 5px #151422;
}

.liquidity_cont_body_conts_cont1 {
  display: flex;
  flex-direction: column;
  position: relative;
}

.swap_price_rate_div {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  background: #152016;
  color: #787690;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 9px;
}

.swap_price_rate_div1 {
  margin-right: 5px;
}

.swap_price_rate_div2 {
  margin-left: 5px;
}

.swap_price_rate_div_swap_icon {
  margin-left: 5px;
  font-size: 17px !important;
  background: #28263e;
  border-radius: 50%;
  padding: 1px;
  cursor: pointer;
}

.moreSwapInfoDiv {
  margin-top: 10px;
  color: #000;
  padding: 1em;
  border: solid 1.3px #e0dfed;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
}

.dark .moreSwapInfoDiv {
  color: #fff;
  border: solid 1.3px #2d2c41;
}

.moreSwapInfoDiv_div1 {
  font-size: 12px;
  border-bottom: solid 1px #e0dfed;
  padding-bottom: 10px;
}

.dark .moreSwapInfoDiv_div1 {
  border-bottom: solid 1px #2d2c41;
}

.moreSwapInfoDiv_div2 {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-top: 10px;
  color: #787690;
}

.moreSwapInfoDiv_div2_area1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.moreSwapInfoDiv_div2_area1_cont2 {
  color: rgb(0, 0, 0);
  display: flex;
  align-items: center;
}

.dark .moreSwapInfoDiv_div2_area1_cont2 {
  color: #fff;
}

.tradeViewAreaCont {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.tradeViewArea {
  width: 100%;
  height: 100%;
  margin-right: 3em;
  background: #0d0c149e;
  padding: 0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(3px);
  border: solid 1px #2d2c41;
}

.tradeViewAreaCont .analytics_container_1_Amount {
  font-size: 28px;
  margin-bottom: 0em;
  color: #a7bbaf;
}

.tradeViewAreaCont1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 2em;
}

.tradeViewAreaCont1_area2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
}

.tradeViewAreaCont1_area2_cont1_active {
  margin-right: 10px;
  cursor: pointer;
  width: 26px;
  height: 24px;
  border-radius: 4px;
  line-height: 24px;
  margin-right: 5px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  transition: all 0.2s ease 0s;
  background-color: #7a5fc0;
  color: #ffffff;
}

.tradeViewAreaCont1_area2_cont1 {
  margin-right: 10px;
  cursor: pointer;
  width: 26px;
  height: 24px;
  border-radius: 4px;
  line-height: 24px;
  margin-right: 5px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  transition: all 0.2s ease 0s;
  color: #b0bbb2;
}

.tradeViewAreaCont_chart_area2 tspan {
  fill: #a7bbaf;
  font-size: 12px;
  font-weight: 400;
}

span.tradeViewAreaCont1_area1_priceChangeSpan {
  font-size: 12px;
  /* color: #31cb9e; */
}

.tradeViewAreaCont_pairs_cont {
  padding: 1em 2em;
}

.tradeViewAreaCont_pairs_cont_div {
  display: flex;
  align-items: center;
}

.tradeViewAreaCont_pairs_cont_div1 {
  width: 25px;
}

.tradeViewAreaCont_pairs_cont_div1_img {
  width: 100%;
}

.tradeViewAreaCont_pairs_cont_div1.moveCloser {
  margin-right: -6px;
  position: relative;
  z-index: 1;
}

.tradeViewAreaCont_pairs_cont_div2 {
  margin-left: 5px;
  font-size: 24px;
  color: #fff;
}

.tradeViewAreaCont_pairs_cont_div2_slash {
  margin-left: 5px;
  font-size: 18px;
  color: #8a968c;
}

.tradeViewAreaCont_pairs_cont_div2.base {
  font-size: 18px;
  color: #8a968c;
}

.swap_base_ticker_price_change_icon {
  color: #000000;
  margin-left: 5px;
  font-size: 16px !important;
  background: #8a968c;
  border-radius: 50%;
  padding: 0px;
  cursor: pointer;
}

.swap_container_settings_cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  color: #787690;
}

.swap_container_settings_cont_area1 {
  font-size: 20px;
}

.swap_container_settings_cont_area2_icon {
  font-size: 20px !important;
  cursor: pointer;
  transition: all 0.2s ease 0s;
  padding: 5px;
  width: 30px !important;
  height: 30px !important;
}

.swap_container_settings_cont_area2_icon2 {
  font-size: 20px !important;
  cursor: pointer;
  margin-left: 15px;
  transition: all 0.2s ease 0s;
  padding: 5px;
  width: 30px !important;
  height: 30px !important;
}

.swap_container_settings_cont_area2_icon:hover {
  background: #28263e80;
  border-radius: 50%;
  padding: 5px;
  /* font-size: 63px; */
  width: 30px;
  height: 30px;
  transition: all 0.2s ease 0s;
}

.swap_container_settings_cont_area2_icon2:hover {
  background: #28263e80;
  border-radius: 50%;
  padding: 5px;
  /* font-size: 63px; */
  width: 30px;
  height: 30px;
  transition: all 0.2s ease 0s;
}

.liquidity_area1 {
  max-width: 425px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.swap_container_settings_cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  /* max-width: 425px; */
  /* width: 100%; */
  color: #000000;
  background: #ebeaff4a;
  padding: 0.5em 1em;
  border-radius: 38px;
  border: solid 1px #ffffff;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}

.dark .swap_container_settings_cont {
  color: #787690;
  background: #0d0c148f;
  border: solid 1px #2d2c41;
}

.tradingView_container_1_chart {
  margin-top: 1em;
}

.swap_container_settings_cont_area2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shareSwapDiv {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #0d0c1496;
  z-index: 1000000;
  overflow: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  backdrop-filter: blur(10px);
}

.shareSwapDiv_cont {
  display: flex;
  width: 100%;
  max-width: 500px;
  margin: auto;
  padding: 1em;
  background: #151422;
  border-radius: 15px;
  border: solid 1px #2d2c41;
  position: relative;
  z-index: 1;
}

.shareSwapDiv_cont1 {
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  margin-right: 20px;
  display: none;
}

.shareSwapDiv_cont1_img {
  width: 100%;
}

.shareSwapDiv_cont2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  align-items: center;
  text-align: center;
  padding: 1em;
}

.shareSwapDiv_cont2_cont1 {
  display: flex;
  flex-direction: column;
}

.shareSwapDiv_cont2_cont1_title {
  font-size: 24px;
  font-weight: 600;
  text-align: left;
}

.shareSwapDiv_cont2_cont1_sub_title {
  margin-top: 15px;
  font-size: 18px;
  text-align: left;
}

.shareSwapDiv_cont2_cont1_body {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 14px;
  margin-top: 2em;
}

.shareSwapDiv_cont2_cont1_body_div1 {
  margin-bottom: 11px;
  color: #7b7899;
  display: list-item;
}

.shareSwapDiv_cont2_cont2 {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-top: 10px;
}

.shareSwapDiv_cont2_cont2_icon {
  background: #686597;
  padding: 10px;
  border-radius: 50%;
  width: 50px !important;
  height: 50px !important;
  cursor: pointer;
  color: #fff;
  margin-bottom: 10px;
}

.shareSwapDiv_cont2_cont2_icon_div {
  flex-direction: column;
  align-items: center;
  display: flex;
  color: #a1bdac;
  font-size: 14px;
  margin-right: 2em;
}

.shareSwapDiv_cont2_cont2_icon_div_last {
  flex-direction: column;
  align-items: center;
  display: flex;
  color: #a1bdac;
  font-size: 14px;
  margin-right: 0;
}

.shareSwapDiv_closeDiv {
  position: absolute;
  width: 100%;
  height: 100%;
  background: none;
  overflow: scroll;
}

.shareSwapDiv_cont_close_icon {
  position: absolute;
  right: 3%;
  color: #fff;
  top: 3%;
  cursor: pointer;
}

.ToggleChartDiv_checkBox_toggle {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 18px;
}

.ToggleChartDiv_checkBox_toggle input {
  display: none;
}

.ToggleChartDiv_checkBox_slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #3b3959;
  transition: 0.4s;
  border-radius: 34px;
  padding: 0px 7px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
}

.ToggleChartDiv_checkBox_slider::before {
  position: absolute;
  content: "";
  height: 29px;
  width: 29px;
  left: -7px;
  bottom: 4px;
  background-color: #151422;
  transition: 0.4s;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  margin: auto;
  border: solid 1px #2d2c41;
  box-shadow: 0px 0px 10px #000;
  background-image: url("./candleStickIcon.png");
  background-position: center;
  background-size: 12px;
  background-repeat: no-repeat;
  background-blend-mode: soft-light;
}

input:checked+.ToggleChartDiv_checkBox_slider {
  background-color: #776d95;
  color: #000;
  font-weight: 600;
}

input:checked+.ToggleChartDiv_checkBox_slider::before {
  transform: translateX(28px);

  left: -1px;
}

.ToggleChartDiv {
  display: flex;
  align-items: center;
  color: #fff;
  margin-bottom: 1em;
  border-radius: 14px;
}

.ToggleChartDiv_txt {
  color: #b5b5b5;
  font-size: 20px;
  margin-right: 15px;
}

.ToggleChartDiv_checkBox_slider.adjustFlex {
  justify-content: flex-end;
  color: #787599;
}

.swap_price_slippage_div {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  /* background: #152016; */
  color: #787690;
  font-size: 12px;
  padding: 5px 0px;
  border-radius: 9px;
  /* border: solid 2px #e0dfed; */
}

.dark .swap_price_slippage_div {
  color: #787690;
  /* border: solid 2px #2d2c41; */
}

.swap_price_slippage_div1 {
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.swap_price_slippage_info_icon {
  font-size: 12px !important;
  margin: 0px 1px;
  cursor: pointer;
}

.swap_price_slippage_div2 {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #28263e;
  cursor: pointer;
}

.dark .swap_price_slippage_div2 {
  color: #fff;
}

.swap_price_slippage_div2_icon {
  /* background: grey; */
  width: 18px !important;
  margin-left: 5px;
}

.max_slippage_display_details_div {
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  margin-bottom: 5px;
}

.max_slippageDisplayDiv {
  background: #0a0912;
  display: flex;
  align-items: center;
  border-radius: 23px;
  /* margin: 0px 10px; */
  margin-bottom: 10px;
  width: 100%;
  font-size: 12px;
  color: #857f95;
  padding: 2px;
  height: 28px;
}

.slippageWarningDiv {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  font-size: 12px;
  color: white;
  background: rgba(255, 153, 1, 0.3);
  border-radius: 50px;
  margin-bottom: 15px;
  font-weight: 300;
}

.slippageWarningDiv_span {
  display: flex;
  align-items: center;
  margin-right: 3px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}

.slippageWarningDiv_span_icon {
  font-size: 18px;
  margin-right: 2px;
  color: #ff9901;
}

.max_slippageDisplayDiv1 {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px;
  border-radius: 50px;
  cursor: pointer;
  color: #847f92;
  border: solid #0a0912;
}

.max_slippageDisplayDiv1:hover {
  border: solid 1px;
}

.max_slippageDisplayDiv1_active {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px;
  border-radius: 50px;
  cursor: pointer;
  color: #ffffff;
  border: solid #7a5fc0;
  background: #211f27;
}

.max_slippageDisplayDiv1_active_warning {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px;
  border-radius: 50px;
  cursor: pointer;
  color: #ffffff;
  border: solid #ff9901;
  background: #211f27;
}

.custom_slippage_input_div {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 100px;
  justify-content: center;
  border: solid #0a0912;
  border-radius: 50px;
  height: 100%;
  color: #6c6989;
}

.custom_slippage_input_div_active {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 100px;
  justify-content: center;
  border: solid #7a5fc0;
  border-radius: 50px;
  height: 100%;
  color: #ffffff;
  background: #21202c;
}

.custom_slippage_input_div_active_warning {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 100px;
  justify-content: center;
  border: solid #ff9901;
  border-radius: 50px;
  height: 100%;
  color: #ffffff;
  background: #21202c;
}

.custom_slippage_input_div:hover {
  border: solid #6c6989;
  color: #6c6989;
}

.custom_slippage_input:focus-visible {
  box-shadow: none;
  border: 0;
}

.custom_slippage_input {
  background: 0;
  border: none;
  width: 100%;
  color: #ffffff;
  text-align: right;
  margin-right: 5px;
}

.custom_slippage_input_div_span {
  margin-right: 15px;
}

@media screen and (max-width: 991px) {
  .tradeViewArea {
    margin-top: 2em;
    margin-right: 0;
  }

  .tradeViewAreaCont1 {
    padding: 1em 1em;
  }

  .tradeViewAreaCont_pairs_cont {
    padding: 1em 1em;
  }
}