.saleDetailsDiv {
  z-index: 100000;
  position: fixed;
  background: #0000004d;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 1em;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  flex-direction: column;
  align-items: center;
}

.saleDetailsDiv_area_closeIcon_div {
  position: relative;
  z-index: 1;
  cursor: pointer;
  background: #ffffff;
  padding: 5px;
  width: 75px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  border-radius: 8px;
  border: solid 1px #a5a3c2;
  margin-bottom: 1em;
}

.dark .saleDetailsDiv_area_closeIcon_div {
  background: #151422;
  color: #fff;
  border: solid 1px #262538;
}

.saleDetailsDiv_area_closeIcon {
  font-size: 17px !important;
  margin-right: 2px;
}

.saleDetailsDiv_area {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px #606060;
  padding: 1em;
  border-radius: 10px;
  max-width: 767px;
  /* height: 100%; */
  overflow: scroll;
  color: #000;
  position: relative;
  z-index: 1;
}

.dark .saleDetailsDiv_area {
  background: #0d0c14;
  box-shadow: 0px 0px 20px 0px #000;
  color: #fff;
}

.saleDetailsDiv_close_div {
  width: 100%;
  height: 100%;
  position: absolute;
  background: none;
  top: 0;
  left: 0;
}

.editProductDiv_close_div {
  width: 100%;
  height: 100%;
  position: absolute;
  background: none;
  top: 0;
  left: 0;
}

.saleDetailsDiv_area_1 {
  padding: 1em;
  border: solid 1px #d2d1e9;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5em;
  margin-bottom: 1em;
}

.dark .saleDetailsDiv_area_1 {
  border: solid 1px #262538;
}

.saleDetailsDiv_area_1_title {
  font-size: 18px;
  color: #787690;
  margin-bottom: 0.5em;
}

.saleDetailsDiv_area_1_div1 {
  padding: 1em;
  background: #eeeeee;
  margin-bottom: 0.5em;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  overflow: scroll;
}

.dark .saleDetailsDiv_area_1_div1 {
  background: #151422;
}

.saleDetailsDiv_area_1_div1_title {
  color: #787690;
}

.PlaceBidDiv {
  padding: 10px;
  background: #151422;
  border-radius: 10px;
  border: solid 1px #2f4738;
  display: flex;
  flex-direction: column;
}

.PlaceBidDiv_Body {
  display: flex;
  flex-direction: column;
  margin-top: 0.5em;
}

.PlaceBidDiv_Body_1 {
  display: flex;
  flex-direction: column;
}

.PlaceBidDiv_Body_1_title {
  font-size: 14px;
  color: #6d8677;
  margin-bottom: 5px;
}

input.PlaceBidDiv_Body_1_input {
  padding: 1em;
  border-radius: 10px;
  background: #0d0c14;
  color: #b0cebc;
  border: solid 1px #2f4738;
}

.PlaceBidDiv_ButtonDiv {
  width: 100%;
  margin-top: 1em;
}

button.PlaceBidDiv_Button {
  width: 100%;
  padding: 1em;
  border-radius: 10px;
  background: #7a5fc0;
  background: #fff;
  color: #000;
  border: none;
}

.PlaceBidDiv .details-accord {
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 0px;
}

.PlaceBidDiv .MuiAccordionDetails-root {
  display: flex;
  padding: 8px 16px 16px;
  padding: 0;
  flex-direction: column;
}

.approveProdButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.approveProdButton_btn {
  padding: 15px;
  padding: 10px;
  width: 89px;
  border-radius: 10px;
  border: none;
  background: #7a5fc0;
  color: #fff;
  margin: auto;
  font-size: 14px;
}

.approveProdButton_btn:disabled {
  background: #446250;
  color: #7d9887;
  cursor: not-allowed;
}

.filter_table_area_1_search {
  position: relative;
  width: 45%;
}

.saleDetailsDiv_area_body_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  /* text-align: center; */
  padding: 10px;
  height: 100%;
}

.saleDetailsDiv_area_title_div {
  font-size: 32px;
  font-weight: 600;
}

.saleDetailsDiv_area_body {
  font-size: 22px;
  color: #9694a3;
}

.saleDetailsDiv_area_body_btn_div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
}

.saleDetailsDiv_area_body_btn {
  width: 100%;
  padding: 15px;
  color: #fff;
  background: #7a5fc0;
  border: none;
  border-radius: 11px;
}

.saleDetailsDiv_area_body_btn:disabled {
  background: #362e4b;
  color: #504962;
  cursor: not-allowed;
}

.checkBox_agree_div_bodyb {
  display: flex;
  margin-top: 0em;
}

.saleDetailsDiv_areab {
  width: 100%;
  background: #0d0c14;
  box-shadow: 0px 0px 20px 0px #000;
  padding: 3em;
  border-radius: 10px;
  max-width: 767px;
  /* height: 100%; */
  overflow: scroll;
  color: #fff;
  position: relative;
  z-index: 1;
}