.success_error_component {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  left: 0;
  z-index: 1110;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 1em;
  padding-top: 7em;
}

.success_modal_div,
.error_modal_div {
  width: 100%;
  max-width: 480px;
  background: #fff;
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  gap: 12px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 10px #000000;
  animation: fade_in 0.5s ease-in-out;
}
.dark .success_modal_div,
.dark .error_modal_div {
  background: #365543;
  box-shadow: 0px 0px 10px #000000;
  color: #fff;
}
.dark .error_modal_div {
  background: #292929;
  box-shadow: 0px 0px 10px #000000;
  color: #fff;
}

.error_modal_div_body_Head {
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  color: #ff575a;
}

@keyframes fade_in {
  0% {
    /* opacity: 0; */
    transform: scale(1);
  }
  50% {
    /* opacity: 0.5; */
    transform: scale(1.3);
  }
  100% {
    /* opacity: 1; */
    transform: scale(1);
  }
}
.success_modal_image,
.error_modal_image {
  width: 100%;
}
.dark .success_modal_image,
.dark .error_modal_image {
  width: 100%;
  display: none;
}
.success_modal_image_dark,
.error_modal_image_dark {
  width: 100%;
  display: none;
}
.dark .success_modal_image_dark,
.dark .error_modal_image_dark {
  width: 100%;
  display: block;
}
.dark .error_modal_image_dark {
  filter: grayscale(1);
  width: 100%;
  display: block;
}

.success_modal_div_body,
.error_modal_div_body {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  padding: 0em 2em;
  text-align: center;
}
.success_modal_button,
.error_modal_button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em 2em;
}
.success_btn {
  background-color: #7a5fc0;
  color: #fff;
  padding: 0.6em 3em;
  border-radius: 5px;
  border: none;
  box-shadow: -1px 7px 19px var(--bs-l-mode-default-green-shadow);
  transition: 0.3s ease-in;
  width: 100%;
}
.error_Btn {
  background-color: #ff575a;
  color: #fff;
  padding: 0.6em 3em;
  border-radius: 5px;
  border: none;
  box-shadow: -1px 7px 19px var(--bs-l-mode-default-green-shadow);
  transition: 0.3s ease-in;
  width: 100%;
  font-weight: 700;
}
.success_modal_div_body_cont {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.success_modal_div_body_Head {
  color: #388f6f;
  font-weight: 700;
  font-weight: 700;
  font-size: 32px;
  text-align: center;
}
.dark .success_modal_div_body_Head {
  color: #a2e6cd;
}
