.pool_deatail_area {
  display: flex;
  flex-direction: column;
  grid-gap: 40px;
  gap: 10px;
  position: relative;
}

.no_paddd {
  padding: 0 !important;
}

.pool_detail_heading {
  display: flex;
  justify-content: space-between;
  margin-top: -2em;
}

.fundin_amnt_left_amnt_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
}

.fundin_amnt_left_amnt {
  font-weight: 600;
}

.pool_detail_heading_area1 {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
}

.pool_detail_heading_area1_img {
  width: 40px;
  border-radius: 5px;
}

.pool_detail_heading_area1_txt_cont {
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: 100%;
}

.pool_detail_heading_area1_invest_btn_div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
}

.pool_detail_heading_area1_txt_cont_2 {
  font-weight: 600;
}

.pool_detail_heading_area1_txt_cont_1 {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.pool_detail_investmentcapacity_box {
  width: 15%;
  border: solid 1px #c5c5c5;
  border-radius: 20px;
  padding: 2px 0px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a8a8a8;
}

button.pool_detail_heading_area1_invest_btn {
  width: 100%;
  padding: 10px 0px;
  border-radius: 10px;
  border: none;
  background: #7a5fc0;
  color: #fff;
}

.pool_detail_sub_area1 {
  background: #fff;
  padding: 1em;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: solid 1px #ececec;
}

.vertical_rule {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  width: 0px;
  align-self: stretch;
  border-left: 1px solid rgb(238, 238, 238);
}

.pool_detail_sub_area1_area1 {
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.pool_detail_sub_area1_area1_cont1 {
  font-weight: 600;
}

.pool_detail_sub_area1_area1_cont2 {
  font-size: 13px;
  color: #8a8a8a;
}

.Asset_Originator_Details_cont {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 2em;
}

.Asset_Originator_Details_cont_heading {
  font-weight: 600;
  font-size: 18px;
}

.Asset_Originator_Details_cont_body {
  display: flex;
  flex-direction: column;
  gap: 25px;
  background: #fff;
  padding: 1em;
  border-radius: 10px;
  border: solid 1px #f0f0f0;
  overflow: scroll;
}

.Asset_Originator_Details_cont_body_txt {
  font-size: 14px;
  font-weight: 300;
}

.Asset_Originator_Details_cont_body_issuer_cont {
  display: flex;
  align-items: center;
  gap: 35px;
}

.Asset_Originator_Details_cont_body_head_img {
  width: 150px;
}

.Asset_Originator_Details_cont_body_issuer_cont_head {
  font-size: 14px;
  color: #9a9a9a;
}

.Asset_Originator_Details_cont_body_issuer_cont_txt {
  font-size: 15px;
  font-weight: 600;
}

.pool_status_cont_heading {
  font-weight: 600;
  font-size: 18px;
}

.pool_status {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 2em;
}

.pool_status_Details_cont_body {
  display: flex;
  justify-content: space-between;
  gap: 35px;
}

.pool_status_Details_cont_body1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 38px;
  padding: 2em;
  border: solid 1px #f0f0f0;
  background: #fff;
  border-radius: 10px;
}

.pool_status_Details_cont_body1_cont1 {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.pool_status_Details_cont_body1_cont2 {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.pool_status_Details_cont_body1_head {
  font-weight: 600;
}

.pool_status_Details_cont_body1_sub_conts {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  color: #3f3f3f;
  align-items: center;
}

.custom_hr {
  border-width: 1px 0px 0px;
  border-top-style: solid;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  width: 100%;
  border-color: #484663;
}

.pool_status_Details_cont_body2 {
  width: 100%;
}

.pool_lending_pages_links {
  display: flex;
  /* align-items: center; */
  gap: 0px;
  font-size: 14px;
  /* margin-bottom: 1em; */
  top: 0px;
  background: #f1f4f6;
  width: 100%;
  padding: 0em;
  left: 0;
  position: sticky;
  z-index: 1;
  /* justify-content: center; */
  border: solid 1px #dee6eb;
  border-radius: 8px;
  overflow: hidden;
}

.pool_lend_details_link {
  color: #8898a3;
  display: flex;
  /* align-items: center; */
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  padding: 2px 10px;
  border-radius: 0;
  width: 15%;
  display: flex;
  justify-content: center;
  padding: 1em 0em;
  transition: 0.3s ease-in-out;
}

.pool_lend_details_link:hover {
  background: #dce2e6;
  color: #75828c;
  transition: 0.3s ease-in-out;
}

.pool_lend_details_link_active {
  align-items: center;
  gap: 5px;
  font-size: 15px;
  background: #fff;
  border-radius: 0;
  border: none;
  border-bottom: solid 1px;
  font-weight: 600;
  width: 15%;
  display: flex;
  justify-content: center;
  padding: 1em;
  transition: 0.3s ease-in-out;
}

/* .vertical_ruleB {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  width: 1px;
  align-self: stretch;
  border-left: 1px solid rgb(214 223 229);
} */
.pool_lend_details_link_active .asset_overview_link_icon {
  background: #c6c5dc;
  color: #7a5fc0;
  padding: 3px;
  font-size: 23px !important;
  border-radius: 50%;
  border: solid 1px;
}

.pool_lend_details_link .asset_overview_link_icon {
  background: #d5e1e9;
  color: #8898a3;
  padding: 3px;
  font-size: 23px !important;
  border-radius: 50%;
  border: solid 1px;
}

.asset_list_modal {
  background: #00000087;
  width: 100vw;
  position: fixed;
  top: 0;
  height: 100vh;
  z-index: 10000000;
  left: 0;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em 1em;
}

.asset_list_modal_container {
  width: 100%;
  max-width: 1066px;
  margin: auto;
  padding: 1em;
  background: #f6f6f6;
  border-radius: 10px;
  box-shadow: 0px 0px 15px #3b3b3b;
  display: flex;
  flex-direction: column;
  /* gap: 2px; */
  height: 100%;
  overflow: scroll;
  position: relative;
}

.asset_list_body_head2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  color: black;
  background: #fff;
  padding: 1em;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border: solid 1px #ededed;
  position: sticky;
  top: 0px;
  background: white;
  font-weight: 600;
  z-index: 1;
  gap: 10px;
  overflow: scroll;
}

.asset_list_txt {
  margin-bottom: 0.5em;
  font-weight: 600;
  color: #000;
  font-size: 18px;
}

.back_btn {
  padding: 5px 20px;
  border-radius: 6px;
  border: solid 1px #7a5fc0;
  background: #7a5fc0;
  color: #ffffff;
}

.bacModal_div {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  padding: 4em 1em;
  z-index: 10000000;
  background: #0000008a;
  backdrop-filter: blur(5px);
  /* align-items: center; */
}

.dark .back_modal_cont_loading {
  background: #151422;
  border: solid 1px #4f695b;
  color: #fff;
}

.back_modal_container {
  width: 100%;
  max-width: 500px;
}

.loading_title {
  margin: 0;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  text-align: center;
}

.loaing_span_para {
  font-weight: 300;
  font-size: 14px;
}

.back_modal_cont {
  width: 100%;
  padding: 1em;
  background: #fff;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 20px;
  position: relative;
}

.back_modal_cont_loading {
  width: 100%;
  padding: 2em;
  background: #fff;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 20px;
  position: relative;
  align-items: center;
}

.back_modal_heading {
  font-size: 18px;
  font-weight: 600;
}

.back_Modal_input_area {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.back_modal_input_amnt_head {
  font-size: 12px;
  color: #585858;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.back_modal_input {
  padding: 15px 10px;
  font-size: 28px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background: #fff;
  box-shadow: 0px 3px 5px #efefef;
  text-align: right;
  width: 100%;
  padding-left: 2em;
  font-weight: 600;
}

.back_modal_input::placeholder {
  font-weight: 600;
  color: #d4d4d4;
}

.back_loan_btn {
  padding: 15px 0px;
  width: 100%;
  border-radius: 10px;
  background: #7a5fc0;
  color: #fff;
  font-weight: 600;
  border: none;
  margin-top: 1em;
}

.back_loan_btn:disabled {
  background: #a8a8a8;
  color: #e7e7e7;
  cursor: not-allowed;
}

.input_space {
  position: relative;
}

.input_dollar_sign {
  position: absolute;
  top: 17px;
  bottom: auto;
  /* margin: auto; */
  left: 10px;
  font-size: 38px !important;
  color: #bcbcbc;
  border-right: solid 1px #cecece;
  /* width: 8% !important; */
  padding: 3px;
  width: 47px !important;
}

.closeBackModalIcon {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  /* background: #e8e8e8; */
  /* border: solid 1px #8e8e8e; */
  color: #363636;
  border-radius: 4px;
}

.dark .closeBackModalIcon {
  color: #ffffff;
}

.back_modal_input_amnt_head_minimum {
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5em;
}

.amount_earned_mnthly {
  padding: 1em;
  border: solid 1px #e9e9e9;
  background: #fff;
  border-radius: 6px;
  font-size: 12px;
  box-shadow: 0px 0px 6px #dddddd;
  color: #898989;
}

.amount_earned_mnthly_value {
  /* font-size: 15px; */
  font-weight: 600;
  color: #000;
}

.mobile_hash_head {
  overflow: hidden;
}

.viaEarnings_modal_cont {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0em;
  background: #0000007d;
  z-index: 10000;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  overflow: scroll;
  animation: fadeIn22 0.5s linear;
  opacity: 1;
}

.viaEarnings_modal_cont_div {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  grid-gap: 10px;
  grid-gap: 10px;
  gap: 10px;
  background: #fff;
  /* padding: 2em; */
  width: 100%;
  max-width: 450px;
  border-radius: 0;
  box-shadow: 0px 0px 10px #000;
  height: 100%;
  justify-content: space-between;
  position: relative;
  animation: slideIn22 0.5s linear;
  overflow: scroll;
}

.dark .viaEarnings_modal_cont_div {
  background: #151422;
  border: solid 1px #262538;
  color: #fff;
}

.viaEarnings_modal_cont_div1_cont1_span1 {
  font-size: 18px;
  font-weight: 600;
}

.viaEarnings_modal_cont_div1 {
  display: flex;
  flex-direction: column;
  padding: 1em;
  gap: 20px;
  margin: 2em 0;
}

.viaEarnings_modal_cont_div1_cont1 {
  display: flex;
  align-items: center;
  padding: 1em;
  border-radius: 5px;
  background: #e5e5e5;
  justify-content: space-between;
}

.viaEarnings_modal_cont_div2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* background: #cccccc; */
  border-radius: 50px;
  /* overflow: hidden; */
  padding: 1em;
  gap: 10px;
  flex-direction: column;
}

.viaEarnings_modal_cont_div_btn {
  width: 100%;
  padding: 1em 0em;
  border-radius: 8px;
  border: none;
  background: #7a5fc0;
  color: #fff;
  font-weight: 600;
  box-shadow: 0px 4px 5px #b6b6b6;
}

.viaEarnings_modal_cont_div_btn2 {
  width: 100%;
  padding: 1em 0em;
  border-radius: 8px;
  border: none;
  background: #7a5fc0;
  color: #fff;
  font-weight: 600;
  box-shadow: 0px 4px 5px #b6b6b6;
}

.asset_originator_heading {
  padding: 2em;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 20px;
  font-weight: 600;
  align-items: center;
  padding-top: 4em;
}

.asset_originator_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 16px;
  font-weight: 400;
}

.pool_detail_heading_area1_img_2 {
  border-radius: 10px;
  overflow: hidden;
  width: 100px;
}

.close_viaEarning_div_btn {
  position: absolute;
  right: 1%;
  padding: 3px;
  font-size: 28px !important;
  background: #e8e8e8;
  border-radius: 5px;
  top: 1%;
  cursor: pointer;
}

@keyframes fadeIn22 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

  /* 0% {
    opacity: 1;
  } */
}

@keyframes slideIn22 {
  0% {
    left: 25%;
  }

  100% {
    left: 0%;
  }
}

.dark .viaEarnings_modal_cont_div1_cont1 {
  background: #263e30;
}

.dark .viaEarnings_modal_cont_div_btn2 {
  box-shadow: 0px 4px 5px #1c1b2c;
}

.dark .viaEarnings_modal_cont_div_btn {
  box-shadow: 0px 4px 5px #1c1b2c;
}

.dark .close_viaEarning_div_btn {
  background: #18281f;
}

@media screen and (max-width: 991px) {
  .pool_detail_sub_area1 {
    background: #fff;
    padding: 0em;
    border-radius: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    border: solid 1px #ececec;
    flex-direction: column;
  }

  .no_paddd {
    padding: 0em 0em !important;
  }

  .pool_detail_sub_area1_area1 {
    align-items: flex-start;
  }

  .vertical_rule {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    width: 0% !important;
    align-self: stretch;
    border-bottom: 1px solid rgb(238, 238, 238) !important;
    /* height: 10px; */
  }

  .pool_lend_details_link {
    width: 20%;
  }

  .pool_lend_details_link_active {
    width: 20%;
  }

  .pool_lending_pages_links {
    top: 15px;
  }

  .pool_detail_heading {
    display: flex;
    justify-content: space-between;
    margin-top: 0em;
  }
}

@media screen and (max-width: 767px) {
  .pool_lend_details_link {
    width: 20%;
    font-size: 14px;
    white-space: nowrap;
  }

  .pool_lend_details_link_active {
    width: 20%;
    font-size: 14px;
    white-space: nowrap;
  }

  .pool_lending_pages_links {
    top: 0px;
    overflow: scroll;
  }

  .finance_fee_details_page {
    display: none !important;
  }

  .finance_fee_details_page_row {
    display: none !important;
  }

  .pool_status_Details_cont_body1 {
    padding: 1em;
  }
}

@media screen and (max-width: 645px) {
  .pool_lend_details_link_active {
    width: 25%;
    font-size: 14px;
    white-space: nowrap;
  }

  .pool_lend_details_link {
    width: 25%;
    font-size: 14px;
    white-space: nowrap;
  }

  .date_time_row {
    display: none !important;
  }

  .date_time_head {
    display: none !important;
  }

  .asset_list_body_head2 {
    padding: 0.5em;
  }

  .back_modal_cont {
    padding: 1em;
  }

  .asset_list_body_head_tab2,
  .asset_list_body_head_tab1,
  .asset_list_body_head_tab3,
  .asset_list_body_head_tab4,
  .asset_list_body_head_tab5,
  .asset_list_body_head_tab6,
  .asset_list_body_head_tab7 {
    font-size: 12px;
    white-space: nowrap;
  }
}

@media screen and (max-width: 500px) {
  .date_duration_row22 {
    display: none !important;
  }

  .pool_lend_details_link {
    width: 100%;
    font-size: 14px;
    padding: 1em;
  }

  .pool_lend_details_link_active {
    width: 100%;
    font-size: 14px;
    padding: 1em;
  }

  .pool_lend_details_link_active .asset_overview_link_icon {
    font-size: 18px !important;
  }

  .pool_lend_details_link .asset_overview_link_icon {
    font-size: 18px !important;
  }
}

/* ================kyc div==================== */
/* ================kyc div==================== */
/* ================kyc div==================== */
.goDoKycDiv {
  position: fixed;
  top: 0;
  left: 0;
  padding: 1em;
  background: #00000085;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(8px);
}

.goDoKycDiv_container {
  width: 100%;
  background: #fff;
  max-width: 400px;
  padding: 1em;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  text-align: center;
  animation: slideFromTop 0.5s linear;
  position: relative;
}

.dark .goDoKycDiv_container {
  background: #151422;
  border: solid 1px #262538;
  color: #fff;
}

.goDoKycDiv_container_head {
  font-size: 24px;
  font-weight: 600;
}

.goDoKycDiv_container_btn_div {
  width: 100%;
  margin-top: 0.5em;
}

.goDoKycDiv_container_btn {
  width: 100%;
  padding: 0.5em;
  border-radius: 10px;
  border: none;
  color: #fff;
  background: #7a5fc0;
}

.goDoKycDiv_container_para {
  font-size: 14px;
}

.goDoKycDiv_container_para2 {
  font-size: 14px;
  font-weight: 600;
}

.goDoKycDiv_container_img {
  width: 125px;
}

@keyframes slideFromTop {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.close_goDoKycDiv_cont {
  position: absolute;
  right: 1%;
  top: 1%;
  cursor: pointer;
}

/* ================kyc div==================== */
/* ================kyc div==================== */
/* ================kyc div==================== */