/* ================================== */
/* ================================== */
/* ================================== */
/* ================================== */
/* login button start */

.LoginBtn {
  background-color: #7a5fc0;
  color: #fff;
  padding: 0.6em 3em;
  border-radius: 5px;
  border: none;
  box-shadow: -1px 7px 19px var(--bs-l-mode-default-green-shadow);
  transition: 0.3s ease-in;
  width: 100%;
  font-weight: 700;
}
.LoginBtn_danger {
  background-color: #ff575a;
  color: #fff;
  padding: 0.6em 3em;
  border-radius: 5px;
  border: none;
  box-shadow: -1px 7px 19px var(--bs-l-mode-default-green-shadow);
  transition: 0.3s ease-in;
  width: 100%;
  font-weight: 700;
}
.LoginBtn:hover {
  background: #5f5f5f;
  color: #fff;
  /* box-shadow: -1px 7px 19px var(--bs-l-mode-default-peach-shadow); */
  transform: translate3d(0, -5px, 0);
  transition: 0.3s ease-in-out;
}
.LoginBtn:disabled {
  background: #b3b3b3;
  box-shadow: -1px 7px 19px #ffffff;
  cursor: not-allowed;
}
/* login button end */
/* ================================== */
/* ================================== */
/* ================================== */
/* ================================== */
