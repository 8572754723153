.token_modal_bg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #00000033;
  z-index: 100000;
  top: 0;
  left: 0;
  backdrop-filter: blur(5px);
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.token_modal_container {
  position: relative;
  z-index: 1000000;
  margin: 0px 0px 2rem;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(247, 248, 250);
  box-shadow: rgb(47 128 237 / 5%) 0px 4px 8px 0px;
  padding: 0px;
  overflow: hidden auto;
  align-self: center;
  max-width: 420px;
  max-height: 60vh;
  min-height: 60vh;
  display: flex;
  border-radius: 20px;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
.token_modal_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.close_icon {
  cursor: pointer;
}
.token_modal_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.remove_token_modal_bg {
  width: 100vw;
  height: 100vh;
  top: 0;
  position: absolute;
  background: #ffffff00;
  left: 0;
  right: 0;
  cursor: pointer;
}
.token_modal_head_area {
  display: flex;
  flex-direction: column;
  padding: 1em;
  grid-gap: 15px;
  gap: 15px;
  border-bottom: solid 1px #ebebeb;
}
.token_modal_input {
  padding: 1em 1em;
  width: 100%;
  border-radius: 20px;
  border: solid 1px #d6d6d6;
}
.token_modal_input:focus-visible {
  outline: none;
  border: solid 1px #51b378;
}
.bases_div {
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
  padding: 0px;
}
.base_div1 {
  border: 1px solid rgb(206, 208, 217);
  border-radius: 10px;
  display: flex;
  padding: 6px;
  -webkit-box-align: center;
  align-items: center;
  margin: 4px;
  cursor: pointer;
}
.base_icon {
  width: 66%;
  width: 25px;
  height: 25px;
  background: radial-gradient(
    white 50%,
    rgba(255, 255, 255, 0) calc(75% + 1px),
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 50%;
  box-shadow: black 0px 0px 1px;
  border: 0px solid rgba(255, 255, 255, 0);
  margin-right: 8px;
}
.base_name {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-weight: 600;
  font-size: 16px;
}
.bases_div_token_body_layer {
  padding: 4px 20px;
  height: 56px;
  display: grid;
  grid-template-columns: auto minmax(auto, 1fr) minmax(0px, 72px);
  gap: 8px;
  cursor: pointer;
  opacity: 1;
  align-items: center;
}
.bases_div_token_body_layer1_head_img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.bases_div_token_body_layer1_token_name {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: 800;
}
.bases_div_token_body_layer1_token_name_sub_head {
  font-size: 12px;
  color: #9e9e9e;
  font-weight: 400;
}

.bases_div_token_body_layer1_amount {
  width: fit-content;
  justify-self: flex-end;
}
.bases_div_token_body {
  height: 100%;
  position: relative;
  /* height: 390px; */
  width: 100%;
  overflow: auto;
  will-change: transform;
  direction: ltr;
}
