.splash_screen_comp {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: rgb(255, 255, 255);
  top: 0;
  z-index: 1000000000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.favicon {
  width: 30%;
  /* animation: rotate 1s linear infinite; */
}

/* @keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */
