.liquidity_area {
  width: 100%;
  /* height: 100vh; */
  display: flex;
  align-items: flex-start;
  position: relative;
  flex-direction: row-reverse;
  justify-content: center;
}

.liquidity_cont {
  position: relative;
  width: 100%;
  background: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px,
    rgb(0 0 0 / 4%) 0px 16px 24px, rgb(0 0 0 / 1%) 0px 24px 32px;
  border-radius: 20px;
  z-index: 1;
}

.liquidity_cont_head {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  padding-bottom: 0;
  align-items: center;
  gap: 20px;
}

.Unloc_btn_div {
  width: 100%;
}

.unlock_input_div {
  width: 100%;
}

.unlock_input {
  padding: 0.5em 1em;
  width: 100%;
  border-radius: 7px;
  border: solid 1px #b8b8b8;
}

.unlock_div {
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  border: solid 1px #dfdfdf;
  border-radius: 10px;
  box-shadow: -1px 5px 10px #e6e6e6;
  /* margin-top: 3em; */
  width: 100%;
  max-width: 450px;
  margin: auto;
  margin-top: 5em;
}

.liquidity_cont_head_text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
}

.settings_icon {
  color: black;
  cursor: pointer;
}

.liquidity_cont_body {
  padding: 1em;
}

.liquidity_cont_body_conts {
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  position: relative;
}

.tips_layer {
  padding: 20px;
  border-radius: 20px;
  background: #ddf2e5;
  color: #137139;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.tips_writeUp {
  font-size: 14px;
}

.tip_sub_head {
  font-weight: 600;
}

.amnt_input {
  width: 100%;
  border-radius: 20px;
  width: 100%;
  border-radius: 12px;
  padding: 0.5em;
  font-size: 32px;
  /* padding-bottom: 1em; */
  /* border: solid 1px #f3f3f3; */
  background: #f3f3f3;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 5px;
  grid-gap: 5px;
  grid-gap: 5px;
  gap: 5px;
  padding: 15px;
  flex-direction: column;
  margin-bottom: 7px;
  padding-bottom: 0.7em;
}

/* .amnt_input:hover {
  border: solid 1px #d2d2d2;
} */
.amnt_input_field:focus-visible {
  border: none;
  outline: none;
  box-shadow: none;
}

.amnt_input_field {
  border: none;
  background: none;
  width: 100%;
  font-weight: 600;
}

.amnt_input_field::placeholder {
  color: #b5b4bf;
}

.dark .amnt_input_field::placeholder {
  color: #3e3c59;
}

.display_tokens_drop {
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.2em 0.5em;
  border-radius: 10px;
  border: none;
  background: #fff;
  box-shadow: 0px 5px 15px #cdcdcd;
  /* width: 65%; */
  white-space: nowrap;
}

.display_tokens_drop_not_select {
  background: #7a5fc0;
  color: #fff;
}

.asset_icon {
  width: 24px;
  /* height: 24px; */
  border-radius: 50%;
  margin-right: 3px;
}

.plus_icon_layer {
  align-items: center;
  width: 100%;
  /* font-size: 16px !important; */
  display: flex;
  animation: ce;
  justify-content: center;
  margin-top: -1.2em;
  margin-bottom: -1.2em;
  position: relative;
}

.plus_icon_layer_icon {
  background: #f3f3f3 !important;
  border-radius: 8px !important;
  border: solid 4px #fff !important;
  font-size: 32px !important;
  color: #7c7c7c !important;
}

.connect_btn_div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.connect_btn {
  width: 100%;
  padding: 1em 0em;
  border-radius: 20px;
  border: none;
  font-weight: 600;
  background: #ddf2e5;
  color: #1a7e42;
}

.connect_btn:hover {
  background: #c5e6d2;
  color: #1a7e42;
}

.disabled {
  filter: grayscale(1);
  cursor: not-allowed;
  opacity: 0.5;
  user-select: none;
  pointer-events: none;
}

.connect_btn:disabled {
  background: #e4e4e4;
  cursor: not-allowed;
  color: #a9a9a9;
}

.sk-folding-cube {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.sk-folding-cube .sk-cube:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #198754;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}

.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}

.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}

.sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

@-webkit-keyframes sk-foldCubeAngle {

  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }

  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }

  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes sk-foldCubeAngle {

  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }

  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }

  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@media screen and (max-width: 991px) {
  .liquidity_area {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
}